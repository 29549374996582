import { default as React, useEffect, useCallback, useState, useMemo } from 'react'
import { message } from 'antd'
import { useRequest } from 'ahooks'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useSnackbar } from 'notistack'
import DocumentMeta from 'react-document-meta'
import { Fanhuishangye } from '@imile/icons'
import { Header, SEO, useI18next } from '@/components'
import { Box } from '@mui/material'
import { useLocation } from '@reach/router'
import { APICareersApply } from '@/components/Careers/api'
import { APIqueryBizDictData } from '@/components/EnquireForm/api'
import CareersForm from '@/components/Careers/CareersForm'
import { Form } from '@/components/EnquireForm/Form'
import { formList } from '@/components/Careers/CareersForm/config'

export const ApplyPage = React.memo((props: any) => {
  const {} = useTranslation()
  const location = useLocation()
  const { navigate } = useI18next()
  const imilePost = props.data?.imilePost
  const [form] = Form.useForm()
  const [meta] = useState(() => {
    return {
      property: {
        'og:title': imilePost?.searchTitle,
        'og:url': location.href,
        'og:image': imilePost?.detailImageUrl,
        'og:description': 'iMile',

        'twitter:card': 'summary_large_image',
        'twitter:title': imilePost?.searchTitle,
        'twitter:description': '',
        'twitter:image': imilePost?.detailImageUrl,
      },
    }
  })
  const [countryAreaCode, setCountryAreaCode] = useState([])

  const id = props?.location?.state?.id || location.search.replace(/\?id=/i, '')
  const careerDetails = props?.location?.state?.careerDetails

  const { run: getWebCountryAreaCode } = useRequest(APIqueryBizDictData, {
    onSuccess: (res) => {
      if (res?.resultObject?.WebCountryAreaCode?.length > 0) {
        const formatData = res.resultObject?.WebCountryAreaCode?.map((item) => ({
          ...item,
          value: item.key,
          label: `${item.value} ${item.key}`,
        }))
        setCountryAreaCode(formatData)
      } else {
        setCountryAreaCode([])
      }
    },
  })

  const apply = useRequest(APICareersApply, {
    manual: true,
    debounceWait: 500,
    onSuccess: (res) => {
      if (res) {
        message.success('success')
        form.resetFields()
      } else {
        message.error('error')
      }
    },
    onError: (err) => {
      console.log(1, err)
    },
  })

  const cityList = useMemo(() => {
    return (
      careerDetails?.city?.split(',')?.map((item) => ({
        key: item,
        value: item,
      })) || []
    )
  }, [careerDetails])

  const { formCustomList } = useMemo(() => {
    return formList({
      countryAreaCode,
      cityList,
      title: careerDetails?.tittle,
    })
  }, [countryAreaCode, cityList, careerDetails])

  const formKeys = useMemo(() => {
    return formCustomList?.map((item) => item.name)
  }, [formCustomList])

  const handleSubmit = useCallback(() => {
    form
      .validateFields(formKeys || [])
      .then((values) => {
        const { file } = values || {}
        const fileUrl = file?.file?.response?.resultObject
        if (!fileUrl) {
          message.warning('Please upload a valid file')
          return
        }
        const params = {
          ...values,
          careerId: id,
          cvUrl: fileUrl,
        }
        delete params.file
        apply.run(params)
      })
      .catch((error) => {
        console.log(error, 'error')
      })
  }, [formKeys, id])

  useEffect(() => {
    getWebCountryAreaCode('WebCountryAreaCode')
  }, [])

  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />

      <DocumentMeta {...meta} />

      <Box sx={{ position: 'absolute' }}>
        <Box
          sx={{
            width: '100%',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            padding: '0 10px',
            fontSize: '0.9rem',
            borderBottom: '1px solid #DFE1EC',
            marginBottom: '20px',
            position: 'fixed',
            background: '#fff',
            top: 55,
            left: 0,
            zIndex: 10,
          }}
        >
          <Box sx={{ marginRight: '0.2rem', display: 'flex', alignItems: 'center' }}>
            <Fanhuishangye
              sx={{
                fontSize: '0.8rem',
                color: '#838895',
                cursor: 'pointer',
                margin: '0 5px',
              }}
              onClick={() => {
                window.history.back()
              }}
            />
          </Box>
          {careerDetails?.tittle || ''}
        </Box>
        <Box
          sx={{
            width: '100%',
            padding: '0px 0px 100px',
            fontSize: '12px',
            overflow: 'auto',
            marginTop: '120px',
          }}
        >
          <CareersForm
            countryAreaCode={countryAreaCode}
            careerDetails={careerDetails}
            form={form}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px 10px 20px',
            borderTop: '1px solid #DFE1EC',
            position: 'fixed',
            left: '0px',
            bottom: '0px',
            background: '#fff',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '2.2rem',
              lineHeight: '2.2rem',
              textAlign: 'center',
              fontSize: '1rem',
              color: '#fff',
              borderRadius: '8px',
              cursor: 'pointer',
              backgroundColor: '#005EDF',
            }}
            onClick={handleSubmit}
          >
            SUBMIT
          </Box>
        </Box>
      </Box>
    </>
  )
})

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["careers"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`

export default ApplyPage
